import React from "react";
import style from "./style.module.css"
import { ProgressSpinner } from 'primereact/progressspinner';
export const InnerLoader = () => {
    return (
        <div className={style.inner_loader_con}>
            Loading
            <div className={style.dot}></div>
            <div className={style.dot}></div>
            <div className={style.dot}></div>
        </div>
    )
}