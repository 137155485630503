import React, { useState } from "react";
import { CreateButton } from "../../../Components/Buttons/CreateButton";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import axios from "axios";

export const CreateAdmin = () => {
    let [dialog, setDialog] = useState(false)

    let [formData, setFormData] = useState(
        {
            name: "",
            email: "",
            password: "",
            mobile: null
        }
    )

    async function ok() {
        try {
            console.log("pkokok")
            let res = await axios.post("http://localhost:3215/admin/create", formData)
            setFormData({
                name: "",
                email: "",
                password: "",
                mobile: null
            })
            console.log(res)

        } catch (error) {
            console.log(error)
        }
    }
    function inpChange(e) {
        let { name, value } = e.target
        setFormData(pre => ({ ...pre, [name]: value }))
    }


    return (
        <div>
            <CreateButton onClick={() => setDialog(true)} label={"Create Admin"} />

            <Dialog header="Create Admin" draggable={false} className="w-50" visible={dialog} onHide={() => setDialog(false)}>
                <label>Admin Name</label>
                <InputText name="name" value={formData.name} onChange={inpChange} className="w-100" placeholder="Admin Name" />
                <br />
                <br />
                <label>Mobile No.</label>
                <InputText type="number" value={formData.mobile} name="mobile" onChange={inpChange} className="w-100" placeholder="Mobile Number" />
                <br />
                <br />
                <label>Email</label>
                <InputText type="email" value={formData.email} name="email" onChange={inpChange} className="w-100" placeholder="Admin Name" />
                <br /><br />
                <label>Password</label>
                <InputText className="w-100" value={formData.password} name="password" onChange={inpChange} placeholder="Password" />
                <br /><br />
                <div className="end">
                    <button className="btn-secondary me-3">Cancel</button>
                    <button className="btn-primary" onClick={ok}>Submit</button>
                </div>
            </Dialog>


        </div>
    )
}