
import style from "./sidebar.module.css"
import { NavLink } from 'react-router-dom';
import { InnerLoader } from "../Loader/InnerLoader";
import { useSelector } from "react-redux";
import { AdminIcon, PaymentIcon } from "../Icons/Icons";
export const Sidebar = ({ children }) => {

    let innerloader = useSelector(e => e.loader.innerLoader)

    return (
        <div className={style.sidebar_main_con}>

            <div className='d-flex'>
                <div className={style.left_main_con}>
                    <br />
                    <br />
                    <div className='mb-5'></div>

                    <div>
                        <NavLink
                            className={style.navLink}
                            to={"/superAdmin/admins"}>
                            <div className="flex-column align_center">
                                <div className="icon">
                                    <AdminIcon />
                                </div>
                                <div>
                                    Admins
                                </div>
                            </div>
                        </NavLink>
                        <br />
                        <NavLink
                            className={style.navLink}
                            to={"/superAdmin/payments"}>
                            <div className="flex-column align_center">
                                <div className="icon">
                                    <PaymentIcon />
                                </div>
                                <div>
                                    Payments
                                </div>
                            </div>
                        </NavLink>
                    </div>

                </div>
                <div className={style.right_main_con}>
                    <div className={`${style.topbar_main_con} d-flex align-items-center`}>
                        <i className='pi pi-align-justify fs-3 ms-2 text-white' />
                    </div>
                    <div className={style.main_child_con}>
                        {children}
                        {innerloader ?
                            <div className={style.loader}>
                                <InnerLoader />
                            </div>
                            : null}
                    </div>
                </div>
            </div>
        </div>


    )
}