import React from 'react'

export const DeleteButton = () => {
    return (
        <span className='icon-button-primary'>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M11.7787 3.00021C11.3887 3.00021 11.009 3.12771 10.695 3.36582C10.2384 3.7127 9.96652 4.25927 9.96652 4.84427V4.89865H14.0333V4.84427C14.0333 4.25927 13.7614 3.71272 13.3049 3.36582C12.9908 3.1277 12.6111 3.00021 12.2211 3.00021H11.7787ZM15.7135 4.89866V4.84428C15.7135 3.73896 15.1998 2.6946 14.3204 2.02812C13.7157 1.56874 12.9788 1.32031 12.2213 1.32031H11.7788C11.0213 1.32031 10.2844 1.56876 9.67974 2.02812C8.80036 2.69467 8.28661 3.73906 8.28661 4.84428V4.89866H3.60013C3.13607 4.89866 2.76013 5.27459 2.76013 5.73866C2.76013 6.20272 3.13607 6.57866 3.60013 6.57866H4.52826V14.2606C4.52826 17.1771 4.9042 19.3577 6.14826 20.7799C7.4242 22.2377 9.40986 22.6803 12.0002 22.6803C14.5905 22.6803 16.576 22.2377 17.8521 20.7799C19.0962 19.3577 19.4721 17.1771 19.4721 14.2606V6.57866H20.4002C20.8643 6.57866 21.2402 6.20272 21.2402 5.73866C21.2402 5.27459 20.8643 4.89866 20.4002 4.89866H15.7135ZM17.792 6.57866H6.20812V14.2606C6.20812 17.1471 6.60937 18.7558 7.4128 19.6738C8.18341 20.555 9.5128 21.0003 11.9999 21.0003C14.487 21.0003 15.8164 20.555 16.587 19.6738C17.3905 18.756 17.7917 17.1473 17.7917 14.2606L17.792 6.57866ZM9.56812 9.93026C10.0322 9.93026 10.4081 10.3062 10.4081 10.7703V16.3615C10.4081 16.8247 10.0322 17.2015 9.56812 17.2015C9.10405 17.2015 8.72812 16.8247 8.72812 16.3615V10.7703C8.72812 10.3062 9.10405 9.93026 9.56812 9.93026ZM14.432 9.93026C14.896 9.93026 15.272 10.3062 15.272 10.7703V16.3615C15.272 16.8247 14.896 17.2015 14.432 17.2015C13.9679 17.2015 13.592 16.8247 13.592 16.3615V10.7703C13.592 10.3062 13.9679 9.93026 14.432 9.93026Z" fill="white" />
            </svg>
        </span>
    )
}
