
import './App.css';
import { Router } from './Router';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, RouterProvider } from 'react-router-dom';
import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';
import { Provider } from 'react-redux';
import { store } from './store/store';
import router from './router/Router';
function App({ pageProps }) {
  return (

    <Provider store={store}>
      <PrimeReactProvider>
        <Router {...pageProps} />

        {/* <RouterProvider router={router} /> */}

      </PrimeReactProvider>
    </Provider>
  )
}

export default App;
