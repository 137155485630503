import React, { Fragment } from 'react'
import { ArrowRightIcon } from '../Icons/Icons'
import "./common.css"

export const BreadCrum = ({ data }) => {
    return (
        <div className='align_center breadCrum_main_con'>
            {data.map((res, key) => {
                return (
                    <div key={key} className='align_center content'>
                        <div className={`pointer ${key === data.length - 1 ? "activeLink" : ""}`} key={key}>{res.label}</div>
                        {key == data.length - 1 ? null :
                            <ArrowRightIcon />}
                    </div>
                )
            })}
        </div>
    )
}
