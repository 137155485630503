import { createSlice } from "@reduxjs/toolkit";

export const LoaderSlice = createSlice({
    name: "loader",
    initialState: {
        innerLoader: false
    },
    reducers: {
        setInnerLoader: (state, action) => {
            return { ...state, innerLoader: action.payload }
        }
    }
})

export let { setInnerLoader } = LoaderSlice.actions