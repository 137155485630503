import React, { useState } from "react";
import { Paginator } from 'primereact/paginator';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';


export const Pagination = ({ page, record_per_page, pageChange, recordPerPageChange, totalRecord }) => {

    const template2 = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        RowsPerPageDropdown: () => {
            const dropdownOptions = [10, 20, 30, 40];
            return (
                <div>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
                        Items per page:{' '}
                    </span>
                    <Dropdown value={record_per_page} options={dropdownOptions} onChange={(e) => {
                        recordPerPageChange && recordPerPageChange(e.target.value)
                    }} />
                </div>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };

    return (
        <div className="d-flex bg-white justify-content-end">
            <Paginator
                template={template2}
                first={(page - 1) * record_per_page}
                rows={record_per_page}
                totalRecords={totalRecord}
                onPageChange={e => pageChange(e.page + 1)} />
        </div>
    )
}
