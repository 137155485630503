import React from 'react'
import { Link, Outlet, useSearchParams } from 'react-router-dom'

export const TestAdmin = () => {

    let [params, setParams] = useSearchParams()
    function ok() {

        setParams({ name: "viraj", k: "ppp" })

    }

    console.log("render render redne")
    return (
        <div>
            <Link to={"/"}>
                <div>Dashboard</div>
            </Link>
            <Link to={"teachers"}>

                <div>Teachers</div>
            </Link>
            <Link to={"students"}>
                <div>Students</div>
            </Link>
            <button onClick={ok}>click</button>
            <Outlet context={"value from parnet"} />
        </div>
    )
}
