import React from 'react';
import { InputText } from "primereact/inputtext";


export const SearchInput = (props) => {
    return (
        <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText {...props} />
        </span>
    )
}