import React, { Suspense } from "react";
import { Route, RouterProvider, Routes, createBrowserRouter, createRoutesFromElements } from "react-router-dom"
import { TestAdmin } from "./Pages/Test/TestAdmin";
import { TestAdminDashboard } from "./Pages/Test/TestAdminDashboard";
import { TestAdminStudents } from "./Pages/Test/TestAdminStudents";
import { TestAdminTeachers } from "./Pages/Test/TestAdminTeachers";
import getAllAdmins, { Admins } from "./Pages/SuperAdmin/Admins/Admins";

let router = createBrowserRouter(createRoutesFromElements(
    <Route path="superAdmin" element={<Admins />} errorElement={<h1>THis is erroor</h1>} loader={getAllAdmins} />
))


export const Router = () => {
    return (
        <RouterProvider router={router} />
    )
}