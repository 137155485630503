
export const apis = {
    admin: {
        create: "/admin/create",
        fetch: "/admin/fetch",
        delete: "/admin/delete/"
    }
}




