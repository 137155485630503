import { useLoaderData, useNavigate } from "react-router"
import { DeleteButton } from "../../../Components/Buttons/DeleteButton"
import { SearchInput } from "../../../Components/Inputs/SearchInput"
import { TableContainer, TableHeader } from "../../../Components/Table/TableContainer"
import { CreateAdmin } from "./CreateAdmin"
import { useEffect, useState } from "react"
import { BreadCrum } from "../../../Components/Common/BreadCrum"
import { fetchAdmins } from "../../../apis/superAdminApis"
import { Pagination } from "../../../Components/Common/Pagination"
import { Sidebar } from "../../../Components/Sidebar/Sidebar"


export default async function getAllAdmins() {
    try {
        return await fetchAdmins(1, 10)
    } catch (err) {
        return err
    }
}



export const Admins = () => {
    let navigate = useNavigate()
    let data = useLoaderData()
    console.log(data.data.response)
    let breadCrumItems = [
        { label: "Admins", link: "superAdmin/admins" },
    ]
    let [page, setPage] = useState(1)
    let [recordPerPage, setRecordPerPage] = useState(10)
    let [adminsData, setAdminsData] = useState([])
    let [totalRecord, setTotalRecord] = useState(0)

    // async function fetchAdminsByPagination(pageNo, record_per_page) {
    //     try {
    //         let res = await fetchAdmins(pageNo, record_per_page)
    //         console.log(res.data.response)
    //         setAdminsData(res.data.response)
    //         setTotalRecord(res.data.totalCount)
    //     }
    //     catch (err) {
    //         console.log(err)
    //     }
    // }

    // useEffect(() => {
    //     fetchAdminsByPagination(page, recordPerPage)
    // }, [page])




    return (
        <>
            <Sidebar>

                <div className="between align_center">
                    <div>
                        <BreadCrum data={breadCrumItems} />
                    </div>
                    <CreateAdmin />
                </div>

                <TableHeader>
                    <SearchInput style={{ width: "350px" }} placeholder="Search Admins" />
                </TableHeader>
                <TableContainer>
                    <table>
                        <thead>
                            <tr>
                                <th><div>Sno</div></th>
                                <th><div>Admin</div></th>
                                <th><div>Email</div></th>
                                <th><div>Action</div></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.data.response?.map((res, index) => {
                                return (
                                    <tr key={res._id}>
                                        <td><div>{index + 1}</div></td>
                                        <td><div>{res.name}</div></td>
                                        <td><div>{res.email}</div></td>
                                        <td><div><DeleteButton /></div></td>
                                    </tr>

                                )
                            })}

                        </tbody>
                    </table>
                </TableContainer>

                <Pagination
                    record_per_page={recordPerPage}
                    page={page}
                    pageChange={setPage}
                    totalRecord={totalRecord}
                // recordPerPageChange={setRecordPerPage}
                />
            </Sidebar>

        </>

    )
}