import { Outlet, createBrowserRouter } from "react-router-dom";
import { Admins } from "../Pages/SuperAdmin/Admins/Admins";
import { Sidebar } from "../Components/Sidebar/Sidebar";

function SidebarOutlet() {
    return (
        <Sidebar>
            <Outlet />
        </Sidebar>
    )
}

let router = createBrowserRouter([
    {
        element: <SidebarOutlet />,
        children: [
            {
                path: "/",
                index: true,
                element: < Admins />
            }

        ]
    }
]);

export default router;